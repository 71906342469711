<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df fww">
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0">
					<div :class="['p10 miw80 cp', {'bce2d6ba': status.checked}]" @click="toggleStatus(s)" v-for="(status, s) in status_tabs" :key="s">
						<div class="fs14 c6">
							{{status.name}}
							<el-tooltip v-if="enrollment_tooltips[s]">
								<div slot="content" v-html="enrollment_tooltips[s]"></div>
								<i class="el-icon-question ch"></i>
							</el-tooltip>
						</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['status_'+s] || 0}}</div>
					</div>
				</div>
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0">
					<div :class="['p10 miw80 cp', {'bce2d6ba': attach.checked}]" @click="toggleattach(a)" v-for="(attach, a) in attach_tabs" :key="a">
						<div class="fs14 c6">
							{{attach.name}}
							<el-tooltip v-if="enrollment_tooltips[a]">
								<div slot="content" v-html="enrollment_tooltips[a]"></div>
								<i class="el-icon-question ch"></i>
							</el-tooltip>
						</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['attach_'+a] || 0}}</div>
					</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">
						{{show_fillter ? '收起' : '筛选'}}
						<el-tooltip>
							<div slot="content" class="lh1_5">
								<p>本列表不支持添加创建，数据来源于人事在员工列表登记，或员工个人档案登记</p>
								<p>若出现同一个姓名多条重复记录，说明有信息资料更新。以最新一条记录为准</p>
							</div>
							<i class="el-icon-question ch"></i>
						</el-tooltip>
					</el-button>
					<el-button type="text" @click="handleCommand('export')">导出选中</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="请输入申请流水/姓名/手机号码/电子邮箱/身份证/银行卡/现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择申请人" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{ employee.name }}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.place_id" placeholder="出生首次登记归属地" filterable multiple collapse-tags>
						<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.census_id" placeholder="身份证件签发归属地" filterable multiple collapse-tags>
						<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择证件类型" v-model="params.id_type" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(type, t) in fields.id_types" :key="t" :label="type" :value="t">
							<span>{{ type }}</span>
							<span class="c9 fs12">{{counts["type_"+t] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择性别" v-model="params.gender" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(gender, g) in genders" :key="g" :label="gender" :value="g">
							<span>{{ gender }}</span>
							<span class="c9 fs12">{{counts["gender_"+g] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择民族" v-model="params.nation" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(nation, n) in fields.nations" :key="n" :label="nation" :value="n">
							<span>{{ nation }}</span>
							<span class="c9 fs12">{{counts["nation_"+n] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择政治面貌" v-model="params.politics" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(politic, p) in fields.politics" :key="p" :label="politic" :value="p">
							<span>{{ politic }}</span>
							<span class="c9 fs12">{{counts["politic_"+p] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择婚姻状况" v-model="params.marriage" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(marriage, m) in fields.marriages" :key="m" :label="marriage" :value="m">
							<span>{{ marriage }}</span>
							<span class="c9 fs12">{{counts["marriage_"+m] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择类型" v-model="params.type" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(type, t) in fields.types" :key="t" :label="type" :value="t">
							<span>{{ type }}</span>
							<span class="c9 fs12">{{counts["type_"+t] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择教育情况" v-model="params.edu" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(edu, e) in fields.edus" :key="e" :label="edu" :value="e">
							<span>{{ edu }}</span>
							<span class="c9 fs12">{{counts["edu_"+e] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="出生日期" value="birthed"></el-option>
						<el-option label="入职日期" value="hired"></el-option>
						<el-option label="登记时间" value="created"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getEnrollments({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="enrollment_table" class="bill scroll-wrapper pa" height="100%" :data="tables" :size="theme.size">
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="登记流水" min-width="200">
						<template slot-scope="scope">
							<el-link type="primary" @click="$refs['enrollment'].open(scope.row);" :disabled="!$utils.update($api.URI_EMPLOYEES_ENROLLMENTS)">{{scope.row.no}}</el-link>
							<p class="c9">{{fields.id_types[scope.row.id_type] || '未知' }} - {{scope.row.id_number}}</p>
						</template>
					</el-table-column>
					<el-table-column label="姓名" min-width="90" prop="name">
						<template slot-scope="scope">
							<p>{{scope.row.name}}</p>
							<p>{{fields.types[scope.row.type] || '未知'}}</p>
						</template>
					</el-table-column>
					<el-table-column label="性别" min-width="50" :formatter="({gender}) => { return genders[gender] || '未知';}"></el-table-column>
					<el-table-column label="民族" min-width="50" :formatter="({nation}) => { return fields.nations[nation] || '未知';}"></el-table-column>
					<el-table-column label="婚姻 / 政治" min-width="120">
						<template slot-scope="scope">
							<p>{{fields.marriages[scope.row.marriage] || '未知'}}</p>
							<p>{{fields.politics[scope.row.politics] || '未知'}}</p>
						</template>
					</el-table-column>
					<el-table-column label="籍贯 / 户籍" min-width="110">
						<template slot-scope="scope">
							<span v-if="scope.row.place">{{scope.row.place.name}}</span> / <span v-if="scope.row.census">{{scope.row.census.name}}</span>
						</template>
					</el-table-column>
					<el-table-column label="最高学历" min-width="160">
						<template slot-scope="scope">
							<p>{{fields.edus[scope.row.education] || '未知'}} <span class="fwb" v-if="scope.row.profession">{{scope.row.profession}}</span></p>
							<p class="c9" v-if="scope.row.graduation">{{scope.row.graduation}}</p>
						</template>
					</el-table-column>
					<el-table-column label="银行账户" min-width="170">
						<template slot-scope="scope">
							<p v-if="scope.row.deposit_bank">{{scope.row.deposit_bank}}</p>
							<p class="c9" v-if="scope.row.bank_number">{{scope.row.bank_number}}</p>
						</template>
					</el-table-column>
					<el-table-column label="联系方式" min-width="160">
						<template slot-scope="scope">
							<p>{{scope.row.mobile}}</p>
							<p class="c9" v-if="scope.row.email">{{scope.row.email}}</p>
						</template>
					</el-table-column>
					<el-table-column label="紧急联系" min-width="100">
						<template slot-scope="scope">
							<p>{{scope.row.emergency_mobile}}</p>
							<p class="c9">{{scope.row.emergency_name}}</p>
						</template>
					</el-table-column>
					<el-table-column label="联系地址" min-width="220">
						<template slot-scope="scope">
							<p>{{scope.row.current_address}}</p>
							<p class="c9">{{scope.row.residence_address}}</p>
						</template>
					</el-table-column>
					<el-table-column label="时间日期" min-width="110">
						<template slot-scope="scope">
							<p>出 {{scope.row.birthed_at}}</p>
							<p>入 {{scope.row.hired_at}}</p>
						</template>
					</el-table-column>
					<el-table-column label="状态" width="60">
						<template slot-scope="scope">
							<el-tooltip v-if="scope.row.approval" :content="scope.row.approval.sp_no">
								<span>{{statuses[scope.row.status] || '未知'}}</span>
							</el-tooltip>
							<span v-else>{{statuses[scope.row.status] || '未知'}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getEnrollments({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getEnrollments({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<enrollment-show ref="enrollment" @refresh="getEnrollments(params)"></enrollment-show>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTableA from '@/components/main-table-a';
	import enrollmentShow from '@/pages/hrm/enrollments/show';

	export default {
		components: {
			mainTableA,
			enrollmentShow
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			fields () {
				return this.models.enrollments || {};
			},
			statuses () {
				return this.fields.status || {};
			},
			attachs () {
				return this.fields.attachs || {};
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			},
			enrollment_tooltips () {
				return this.fields.tooltips || {};
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getEnrollments(this.params);
			},
			async getEnrollments (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_ENROLLMENTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.counts = result.counts;
				this.employees = result.employees;
				var status_tabs = {};
				const status = params.status || [];
				for (let s in this.statuses) {
					status_tabs[s] = {
						name: this.statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				var attach_tabs = {};
				const attach = params.attach || [];
				for (let s in this.attachs) {
					attach_tabs[s] = {
						name: this.attachs[s],
						checked: attach.indexOf(s) >= 0 ? true : false
					};
				}
				this.attach_tabs = attach_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			handleCommand (e) {
				const { selection } = this.$refs.enrollment_table;
				if (!selection.length) return this.$message.error('请选择需要操作的档案');
				switch (e) {
					case 'export':
						var header = ['结算年月', '员工姓名', '入职时间', '离职时间', '入职类型', '证件类型', '证件号码', '开户银行', '银行号码', '签名确认'];
						var filter = ['period_at', 'employee_name', 'hired_at', 'separated_at', 'enrollment_type', 'id_type', 'id_number', 'deposit_bank', 'bank_number', 'signatured'];
						this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$utils.handleDownload([header], filter, selection, "薪酬导出" + this.$moment().format('YYYYMMDDHHmmss'), "薪酬列表");
						});
					break;
				}
			}
		},
		data() {
			return {
				tables: [],
				counts: [],
				genders: {1: '男', 2: '女'},
				employees: [],
				attach_tabs: [],
				status_tabs: [],
				show_fillter: false,
				enrollment: {},
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getEnrollments(this.params, true);
		}
	};
</script>